import React, { useState } from "react";
// import contactUsImage from "../../../Assets/Home/contactUsImage.svg";
import contactUsImage from "../../../Assets/Home/contact1.png";
import contactStyle from "../Home.module.scss";
import styles from "../../../Layouts/BasicLayout/Header/Header.module.scss";
import axios from 'axios'

let axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  }
};

const ContactUs = () => {

  const [formData, setFormData] = useState({ userName: "", email: "", location: "", establishment: "", chargerType: "", bikePark:"",carPark:"" })
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [message, setMessage] = useState()

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setBtnDisabled(true)
    console.log(formData);
    var finalData = {
        to:"siddhant@uex.ai",
        subject:"Charger Installation Request",
        ...formData,
        html:`<p>Hello,</p> <br> <p>This is an automated mail containing information reagarding a contact request. Following are the details:<br> Name: <b>${formData.userName}</b> <br> Email: <b>${formData.email}</b> <br> Type of Establishment: <b>${formData.establishment}</b> <br> Location: <b>${formData.location}</b> <br> Type of charger: <b>${formData.chargerType}</b> <br> No. car park: <b>${formData.carPark}</b> <br> No. bike park: <b>${formData.bikePark}</b></p>`
    }

    axios.post('https://ec2-43-204-109-132.ap-south-1.compute.amazonaws.com/api/v1/send_email_contactus', finalData, axiosConfig)
      .then((res) => {
        setMessage('Contact Request Sent')
        setFormData({ userName: "", email: "", location: "", establishment: "", chargerType: "", bikePark:"",carPark:"" })
        setTimeout(() => {
          setMessage()
        }, 10000);
      })
      .catch((err) => {
        console.log(err)
        setMessage(err.message + '. Please try again after some time.')
      })
    setBtnDisabled(false)
  }
  return (
    <>
      <div className={`row h100 ${contactStyle.mainContainer} justify-content-center align-items-center`}>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center">
          <img
            src={contactUsImage}
            alt="contact us"
            className={`${contactStyle.imageContainer} img-fluid`}
          />
        </div>


        <div className="col-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-start align-items-center w-43">

          <div className={` ${contactStyle.textDataContainer}`}>
            <div className={`${contactStyle.titleContainer} mb-4`}>
              <p>INVEST EASY WITH ZIZI</p>
              <h4>Start your charging station today and join the booming USD 100 Billion market
              </h4>
            </div>
            <form onSubmit={handleSubmit}>
              <div className={`${contactStyle.formContainer}`}>
                <div className="row">
                  <div className="col-sm-6 mt-3">
                    <div>
                      <input
                        required
                        type="text"
                        className={`form-control border-0 border-bottom ${contactStyle.leftPlaceholder}`}
                        placeholder="Your Name"
                        name="userName"
                        value={formData?.userName}
                        style={{ margin: 0 }}
                        onChange={handleChange}
                      />
                      {/* <label>Your Name</label> */}
                    </div>
                  </div>

                  <div className="col-sm-6 mt-3">
                    <div>
                      <input
                        type="email"
                        className={`form-control border-0 border-bottom ${contactStyle.leftPlaceholder}`}
                        value={formData?.email}
                        placeholder="Email ID"
                        name="email"
                        required
                        onChange={handleChange}
                      />
                      {/* <label>Email ID</label> */}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 mt-3">
                    <div>
                      <input
                        type="text"
                        className={`form-control border-0 border-bottom ${contactStyle.leftPlaceholder}`}
                        placeholder="No of Car Parking"
                        value={formData?.carPark}
                        name="carPark"
                        style={{ margin: 0 }}
                        onChange={handleChange}
                      />
                      {/* <label>Your Name</label> */}
                    </div>
                  </div>

                  <div className="col-sm-6 mt-3">
                    <div>
                      <input
                        type="text"
                        className={`form-control border-0 border-bottom ${contactStyle.leftPlaceholder}`}
                        placeholder="No of Bike Parking"
                        value={formData?.bikePark}
                        name="bikePark"
                        onChange={handleChange}
                      />
                      {/* <label>Email ID</label> */}
                    </div>
                  </div>
                </div>
                <input
                  type="text"
                  className={`form-control mt-3 border-0 border-bottom ${contactStyle.leftPlaceholder}`}
                  placeholder="Enter Location"
                  name="location"
                  value={formData?.location}
                  required
                  style={{ margin: 0 }}
                  onChange={handleChange}
                />
                <select value={formData?.establishment} name='establishment' onChange={handleChange} className={`form-select my-3 border-0 border-bottom ${contactStyle.leftPlaceholder}`}>
                  <option value="">Type of Establishment</option>
                  <option value="Commercial Complex">Commercial Complex</option>
                  <option value="College / Schools">College / Schools</option>
                  <option value="Government Building">Government Building</option>
                  <option value="Hotel">Hotel</option>
                  <option value="Hospital">Hospital</option>
                  <option value="Petrol Pump">Petrol Pump</option>
                  <option value="Personal">Personal </option>
                  <option value="Residential Society">Residential Society</option>
                  <option value="Restaurent"> Restaurent</option>
                </select>
                <select value={formData?.chargerType} name='chargerType' onChange={handleChange} className={`form-select my-3 border-0 border-bottom ${contactStyle.leftPlaceholder}`}>
                  <option value="">Type of Charger</option>
                  <option value="Zizi Rapid">Zizi Rapid</option>
                  <option value="Zizi Steady">Zizi Steady</option>
                </select>
                <p style={{color:'#83A954'}}>{message}</p>
                <button
                  disabled={btnDisabled}
                  className={`${styles.joinUsBtn} d-flex justify-content-center align-items-center mt-4`}
                  id="submit"
                  value="Submit">
                  Get Zizi
                </button>

              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
import React from "react";
import mainFeatureStyle from "../Home.module.scss";
import network from "../../../Assets/Home/network.svg"
import stackLogo from "../../../Assets/Home/stackLogo.svg"
import arrow from "../../../Assets/Home/arrow.svg"
import pen from "../../../Assets/Home/pen.svg"
import leaf from "../../../Assets/Home/leaf.svg"
import arai from "../../../Assets/Home/arai.svg"


const MainFeatures = () => {
  return (
    <>
      <div className={`container-fluid h100 ${mainFeatureStyle.featureContainer}`}>
        {/* for text */}
        <div className={`${mainFeatureStyle.textContainer} mx-auto mt-1`}>
          <h4>TECHNICAL U.S.P.</h4>
          <p>ZIZI MADE FOR ROBUST BUILT WITH GLOBAL TECHNOLOGY</p>
         
        </div>

        {/* for the column */}

        <div className="container">
          <div className="row">
            <div
              className={`col-xs-12 col-sm-12 col-md-6 col-lg-4 mb-3 ${mainFeatureStyle.featureColumn}  d-flex justify-content-center align-items-center`}
            >
              <div
                className={`container-fluid ${mainFeatureStyle.mainCardContent} `}
              >
                <img src={network} alt="network logo" className="mb-3" />
                <p>POWERED BY ADVANCED GERMAN TECHNOLOGY TO DELIVER GLOBAL STANDARDS</p>
              </div>
            </div>

            <div
              className={`col-xs-12 col-sm-12 col-md-6 col-lg-4  mb-3 ${mainFeatureStyle.featureColumn}  d-flex justify-content-center align-items-center`}
            >
              <div
                className={`container-fluid ${mainFeatureStyle.mainCardContent} `}
              >
                <img src={stackLogo} alt="stackLogo logo" className="mb-3" />
                <p>INNOVATIVE STACKING TECHNOLOGY FOR FUTURE CAPACITY UPGRADATION</p>
              </div>
            </div>
            <div
              className={`col-xs-12 col-sm-12 col-md-6 col-lg-4 mb-3 ${mainFeatureStyle.featureColumn}  d-flex justify-content-center align-items-center`}
            >
              <div
                className={`container-fluid ${mainFeatureStyle.mainCardContent} `}
              >
                <img src={arrow} alt="arrow logo" className="mb-3" />
                <p>SMART EV RECOGNITION ENABLES AUTO SWAP BETWEEN ISO AND DIN EV’s</p>
              </div>
            </div>

            {/* second row */}

            <div
              className={`col-xs-12 col-sm-12 col-md-6 col-lg-4 mb-3 ${mainFeatureStyle.featureColumn}  d-flex justify-content-center align-items-center`}
            >
              <div
                className={`container-fluid ${mainFeatureStyle.mainCardContent} `}
              >
                <img src={pen} alt="pen logo" className="mb-3" />
                <p>CUSTOMISABLE AS PER CLIENT REQUIREMENT – W.R.T GUNS, INTERFACE, MOUNTING</p>
              </div>
            </div>

            <div
              className={`col-xs-12 col-sm-12 col-md-6 col-lg-4 mb-3 ${mainFeatureStyle.featureColumn}  d-flex justify-content-center align-items-center`}
            >
              <div
                className={`container-fluid ${mainFeatureStyle.mainCardContent} `}
              >
                <img src={leaf} alt="leaf logo" className="mb-3" />
                <p>ENERGY EFFICIENT TO KEEP STANDBY POWER LOSS LOWER THAN 50W</p>
              </div>
            </div>
            <div
              className={`col-xs-12 col-sm-12 col-md-6 col-lg-4 mb-3 ${mainFeatureStyle.featureColumn}  d-flex justify-content-center align-items-center`}
            >
              <div
                className={`container-fluid ${mainFeatureStyle.mainCardContent} `}
              >
                <img src={arai} alt="arai logo" className="mb-3" />
                <p>A.R.A.I. CERTIFIED FOR PUBLIC CHARGING</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainFeatures;
import React, { useEffect, useState } from 'react'
import productStyle from "../Home.module.scss"
import image1 from "../../../Assets/Home/image1.png"
import image2 from "../../../Assets/Home/image2.png"
import image3 from "../../../Assets/Home/image3.png"
import image4 from "../../../Assets/Home/image4.png"
import Slider from "react-slick";

const Products = () => {

    const [isMobile, setIsMobile] = useState(false);


    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 767px)");

        // defining callbacks
        function handleMediaQueryChange(e) {
            setIsMobile(e.matches);
        }

        mediaQuery.addEventListener("change", handleMediaQueryChange);

        // checking size initially
        setIsMobile(mediaQuery.matches);

        // Clean up by removing eventListener
        return () => {
            mediaQuery.removeEventListener("change", handleMediaQueryChange);
        };
    }, []);


    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1401,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                }
            },
            {
                breakpoint: 556,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    arrows: false,
                }
            },

        ]
    };


    const arrs = [
        {
            "a": 1,
            "images": image1,
            "p1": "RAPID",
            "p2": "WALL",
            "p3": "ZIZI",
            "p4": "RAPID ",
            "p5": "DC FAST – WALL MOUNT",
            "p6": "30 KW / 60 KW",
            "p7": "DUAL GUN AVAILABLE",
            "p8": "GB/T - CCS2 – CHAdeMo",
            "p10": "SERIES",

        },
        {
            "a": 2,
            "images": image2,
            "p1": "RAPID",
            "p2": "FLOOR",
            "p3": "ZIZI",
            "p4": "RAPID ",
            "p5": "DC FAST – FLOOR MOUNT",
            "p6": "30 KW / 60 KW / 80 KW / 120 KW / 240 KW",
            "p7": "DUAL GUN AVAILABLE",
            "p8": "GB/T - CCS2 – CHAdeMo",
            "p9": "CHARGE TIME <45 MINS",
            "p10": "SERIES",
        },
        {
            "a": 3,
            "images": image3,
            "p1": "STEADY ",
            "p2": "PLUG",
            "p3": "ZIZI",
            "p4": "STEADY ",
            "p5": "AC SLOW – WALL OR FLOOR MOUNT",
            "p6": "3.3 KW X 3 NOS (INDUSTRIAL SOCKET)",
            "p7": "AC 001",
            "p8": "CHARGE TIME 10 HRS",
            "p10": "SERIES",
        },
        {
            "a": 4,
            "images": image4,
            "p1": "STEADY ",
            "p2": "GUN",
            "p3": "ZIZI",
            "p4": "STEADY ",
            "p5": "AC SLOW – WALL OR FLOOR MOUNT",
            "p6": "7.5 KW / 22 KW",
            "p7": "DUAL GUN AVAILABLE",
            "p8": "CHARGE TIME 10 HRS",
            "p10": "SERIES",
        },
    ]



    return (
                    <>
                        <div className='row h100'>
                            <div className='col-md-12'>
                                <div className={`${productStyle.mainHeading}`}>
                                    <h3>ZIZI EV CHARGERS</h3>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <Slider {...settings} className={`eventSlider`}>
                                    {
                                        arrs?.map((item, index) => {
                                            return (
                                                <>
                                                    <div key={index}>
                                                        <div className="row justify-content-center">
                                                            <div className={` ${productStyle.productImage} `}>
                                                                <img src={item?.images} alt="fourth product" />
                                                                <div className={`${productStyle.hoverProducts}`}>
                                                                <p>{item?.p5}</p>
                                                                <p>{item?.p6}</p>
                                                                <p>{item?.p7}</p>
                                                                <p>{item?.p8}</p>
                                                                <p>{item?.p9}</p>
                                                                </div>
                                                            </div>
                                                            <div className={` ${productStyle.productText} `}>
                                                                <h5><span className={index<=1?`${productStyle.colorFirst}`:`${productStyle.colorSecond}`}>{item?.p1} </span> {item?.p2}</h5>
                                                                <p>{item?.p3} <span className={index<=1?`${productStyle.colorFirst}`:`${productStyle.colorSecond}`}>{item?.p4}</span>{item?.p10}</p>
                                                           {
                                                            isMobile ? 
                                                            <>
                                                            <p>{item?.p5}</p>
                                                                <p>{item?.p6}</p>
                                                                <p>{item?.p7}</p>
                                                                <p>{item?.p8}</p>
                                                                <p>{item?.p9}</p>
                                                            </>
                                                            :""
                                                           }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </Slider>
                            </div>
                        </div>
        </>
    )
}

export default Products;
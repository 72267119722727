import React, { useState } from "react";
import tabNavStyle from "../Home.module.scss";
import wallet from "../../../Assets/Home/wallet.svg";
import dash from "../../../Assets/Home/dash.svg";
import mobileapp from "../../../Assets/Home/mobileapp.svg";
import remote from "../../../Assets/Home/remote.svg";
import appleLogoBw from "../../../Assets/Home/appleLogoBw.svg";
import googleLogoBw from "../../../Assets/Home/googleLogoBw.svg";
import phoneDevice from "../../../Assets/Home/Mockup.png";
import dashboard from "../../../Assets/Home/mac.png";
import wallet1 from "../../../Assets/Home/iPhone.png";
import navigateImage from "../../../Assets/Home/navigateImage.png";
import payImage from "../../../Assets/Home/payImage.png";
import chargeImage from "../../../Assets/Home/chargeImage.png";


const items = [
  { image: mobileapp, alternate: "logo", text: "Mobile App" },
  { image: dash, alternate: "logo", text: "Dashboard" },
  { image: remote, alternate: "logo", text: "Remote Monitoring" },
  { image: wallet, alternate: "logo", text: "Wallet" },
];

const Navigate = () => {

  const [activeItem, setActiveItem] = useState(0);
  const [currentLink, setCurrentLink] = useState("find");

  const handleItemClick = (index) => {
    
    setActiveItem(index);
    (index === 0 && setCurrentLink("find") );
    console.log("item clicked", index);
  };
  const imgArr = [phoneDevice, dashboard, dashboard, wallet1];


  // for the button clicked inside the first tab

  const handleButton = (text) => {
    setCurrentLink(text);
    console.log("the link clicked is", text);
  }

  return (
    <>
    
      <div id="navigation_section" className={`container-fluid h100 ${tabNavStyle.NavigateContainer} my-5`}>
        <div className="row">
          <div className="col-4 col-sm-4 col-md-2 col-lg-2 d-flex flex-column justify-content-between align-items-center">
            {/* tab content */}

            {items.map((item, index) => (
              <div
                key={index}
                className={
                  index === activeItem
                    ? `d-flex flex-column justify-content-center align-items-center  ${tabNavStyle.cardContent}`
                    : `d-flex flex-column justify-content-center align-items-center ${tabNavStyle.cardContentInactive} `
                }
                onClick={() => handleItemClick(index)}
              >
                <img src={item.image} alt={item.alternate} />
                <p>{item.text}</p>
              </div>
            ))}
          </div>

          {/* displaying data on the right side content based on the tab clicked */}
          <div
            className={`col-8 col-sm-8 col-md-5 col-lg-5 ${tabNavStyle.imageContainer}  ${tabNavStyle.mobImage}`}
          >
            {/* image content */}
            {activeItem === 0 ? ( currentLink === "find" ? ( <img
            className={ `${tabNavStyle.mainimage} img-fluid`}
              src={phoneDevice}
              alt="phone logo"
            />) : currentLink === "navigate" ?(<img
            className={ `${tabNavStyle.mainimage} img-fluid`}
              src={navigateImage}
              alt="phone logo"
            />)  : currentLink === "pay" ? (<img
            className={ `${tabNavStyle.mainimage} img-fluid`}
              src={payImage}
              alt="phone logo"
            /> ) : currentLink === "charge" ? (<img
            className={ `${tabNavStyle.mainimage} img-fluid`}
              src={chargeImage}
              alt="phone logo"
            />) : 
              <img
            className={ `${tabNavStyle.mainimage} img-fluid`}
              src={imgArr[activeItem]}
              alt="phone logo" />) : 
            activeItem === 3 ? <img
            className={ `${tabNavStyle.mainimage} img-fluid`}
              src={imgArr[activeItem]}
              alt="phone logo" /> :
            
            <img
            className={`img-fluid ${tabNavStyle.Dashimage}`}
            src={imgArr[activeItem]}
            alt="phone logo"
            />}
            {/* <img
            className={activeItem === 0 || activeItem === 3 ? `${tabNavStyle.mainimage} img-fluid` : `img-fluid ${tabNavStyle.Dashimage}`}
              src={activeItem === 0 ? phoneDevice : activeItem === 1 ? dashboard : activeItem === 2 ? dashboard : activeItem === 3 ? wallet1 : 'alt'}
              alt="phone logo"
            /> */}
          </div>
          <div className=" col-12 col-sm-12 col-md-5 col-lg-5 d-flex justify-content-center align-items-center">
            <div className={`${tabNavStyle.textContent} w-90 my-3`}>
              <div className={` ${tabNavStyle.navigateTextContainer}`}>
                {activeItem === 0 && (
                  <>
                    <h4 className="mb-4">MOBILE APP</h4>
                    <h3>
                    ZIZI GRID - MOBILE APP
                    </h3>
                    <p>
                    ZIZI Grid provides a seamless experience for finding, navigating, paying and charging your EV
                    </p>
                    <div className={tabNavStyle.buttonContainer}>
                    <button className={currentLink === "find" ? tabNavStyle.clickedLink : tabNavStyle.inactiveLink} onClick={() => handleButton("find")}>Find</button>
                    <button className={currentLink === "navigate" ? tabNavStyle.clickedLink : tabNavStyle.inactiveLink} onClick={() => handleButton("navigate")}>Navigate</button>
                    <button className={currentLink === "pay" ? tabNavStyle.clickedLink : tabNavStyle.inactiveLink} onClick={() => handleButton("pay")}>Pay</button>
                    <button className={currentLink === "charge" ? tabNavStyle.clickedLink : tabNavStyle.inactiveLink} onClick={() => handleButton("charge")}>Charge</button>
                    </div>
                    </>
                )}
                {activeItem === 1 && (
                  <>
                    <h4 className="mb-4">DASHBOARD</h4>
                    <h3>
                    ZIZI GRID – CLIENT DASHBOARD
                    </h3>
                    <p>
                    The client dashboard is a super intuitive way to understand and analyze how your ZIZI Chargers are working.
                    </p>
                  

                  </>
                )}
                {activeItem === 2 && (
                  <>
                    <h4 className="mb-4">REMOTE MONITORING</h4>
                    <h3>
                    ZIZI GRID – REMOTE MONITORING
                    </h3>
                    <p>
                    Understand the health of your ZIZI charger in the comfort of your office or home
                    </p>
                  </>
                )}
                {activeItem === 3 && (
                  <>
                    <h4 className="mb-4">WALLET</h4>
                    <h3>
                    ZIZI GRID – WALLET
                    </h3>
                    <p>
                    With ZIZI’s personal wallet, all your payments are securely collected with automated invoice management
                    </p>
                  </>
                )}
              </div>
              <div className={`${tabNavStyle.logoContainer}`}>
                <a href="https://apps.apple.com/us/app/zizi-ev/id6445814482" target="_blank"><img src={appleLogoBw} alt="apple logo" /></a>

                <a href="https://play.google.com/store/apps/details?id=com.statiq.nle.zizi" target="_blank"><img src={googleLogoBw} alt="google logo" /></a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default Navigate;

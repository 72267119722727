import Home from "../Pages/Home/Home";


export const navLinks = [
    {
        title: 'Home',
        path: "/",
        exact: true,
        element: <Home />
    },
    {
        title: 'EV Chargers',
        path: "/#product_section",
        exact: true,
        element: <Home />
    },
   {
        title: 'Li Ion Solution',
        path: "/#product_section",
        exact: true,
        element: <Home />
    },
    {
        title: 'Zizi Grid',
        path: "/#product_section",
        exact: true,
        element: <Home />
    }
   
]